var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body",style:({
  backgroundImage: 'url(' + _vm.data.backgroundCover + ')',
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
})},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.data.description)+" ")])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":"https://www.tongyuan.cc/templets/seowhy/img/fa_banner-fr.png"}})])])
}]

export { render, staticRenderFns }